import { useState, useEffect } from 'react';
import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import TabComponent from "../../../common/TabComponent";
import { apiService } from "../../../service/api.service";
import Pagination from "react-js-pagination";
import { getStatus, FilterStatus, errorHandling,formatToColombianCurrency,socketUrl,timeRemaining} from "../../../config/config"
import moment from "moment";
import Loader from "../../../common/Loader";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../../../i18n';
import { Stomp } from '@stomp/stompjs';
import TimerComponent from '../timerComponent.js';
const OrderManagementView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalItems, settotalItems] = useState();
    const [status, setstatus] = useState(null);
    const [search, setsearch] = useState(null);
    const [responseTime, setresponseTime] = useState();
    
    const [orders, setorders] = useState();
    const redirect = (type) => {
        navigate(type);
    }
    function handlePageChange(pageNumber) {
        setPage(pageNumber - 1);
        Getorders(pageNumber - 1, limit, status, search)
    }
    useEffect(() => {
        // Set up WebSocket connection
        const client = Stomp.client(socketUrl);
        //
        client.connect({}, () => {
            // /subscribe/user/{identifier}'
            console.log('/subscribe/user/' + localStorage.getItem("user"))
            client.subscribe('/subscribe/user/' + localStorage.getItem("user"), (message) => {
              console.log('event====',message)
              Getordersload(page, limit, status, search)
              
            });
    
            client.onStompError = (error) => {
                console.error('STOMP Error:', error);
            };
    
            // Optional: Add debug logging
            client.debug = (msg) => {
                console.log('STOMP Debug:', msg);
            };
    
            // Clean up function
            return () => {
                client.disconnect();
            };
        })
    
    
    }, []);
    useEffect(() => {
        const currentLocale = moment.locale();
        console.log('Current Locale:', currentLocale);
        Getorders(page, limit, status, search)
    }, [])
    const Getordersload = (page, limit, status, search) => {
        console.log("===orders====", page, limit, status, search)
      
        apiService.getOrders(page, limit, status, search).then((res) => {
            console.log("===orders====", res.data.data)
            setorders(res.data.data.list)
            settotalItems(res.data.data.total)
            setresponseTime(res.data.data.responseTime)
            
        }).catch((err) => {
           
            // console.log(err)
            errorHandling(err.response.status)
            console.log("contact us api error", err.response);
        });
    }
    const Getorders = (page, limit, status, search) => {
        console.log("===orders====", page, limit, status, search)
        setLoader(true)
        apiService.getOrders(page, limit, status, search).then((res) => {
            console.log("===orders====", res.data.data)
            setorders(res.data.data.list)
            settotalItems(res.data.data.total)
            setresponseTime(res.data.data.responseTime)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            // console.log(err)
            errorHandling(err.response.status)
            console.log("contact us api error", err.response);
        });
    }
   
    const onStatusChange = (statusVal) => {
        if (statusVal == "All") {
            statusVal = null
        }
        setPage(0)
        setstatus(statusVal)
        Getorders(0, limit, statusVal, search)
    }
    const onSearchChange = (searchVal) => {

        if (searchVal && searchVal.trim() != "") {
            setsearch(searchVal)
            setPage(0)
            Getorders(0, limit, status, searchVal)
        }

    }
    const handleClick = () => {
        setPage(0)
        setsearch(null)
        Getorders(0, limit, status, null)

    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && search && search.trim() != "") {
            // Call your function here
            setPage(0)
            Getorders(0, limit, status, search)
        }
    };
 
    return (<>
        <div className="height-footer order-managemnt-section">
            {loader && <Loader />}
            <Container>
                <TabComponent />
                <div className="tab-pane" id="product-management">
                    <div className='product-management common-under-padding'>
                        <div className='product-header-area status-bar ' >
                            <h2 className='subheading-under'>{t("order_management")}</h2>
                            <div className='under-heading'>
                                <div className='search-box position-relative status-bar'>
                                    <img
                                        className="search-img"
                                        src={require("../../../assets/images/search.svg").default}
                                        alt="img"
                                        onClick={() => onSearchChange(search)}
                                    />
                                    <Form.Control type="input" placeholder={t("search_by_customer")} value={search == null ? "" : search} onChange={(e) => setsearch(e.target.value)} onKeyDown={handleKeyPress} />
                                    {search != null && <i class="fa fa-times" aria-hidden="true" onClick={handleClick}></i>}
                                </div>
                                <div className='select-box-mobile me-3'>
                                    <Form.Select aria-label="Default select example" onChange={(e) => onStatusChange(e.target.value)}>
                                        {/* <option value={"All"}>Select Status </option> */}
                                        <option value={"All"}>{t("all")} </option>
                                        {FilterStatus && FilterStatus.map((data) => {
                                            return (<option value={data.value}>{t(data.label)}</option>)
                                        })}

                                    </Form.Select>
                                </div>

                            </div>
                        </div>
                        <div className='table-data'>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>{t("orderid")}</th>
                                        {/* <th>Product Name</th> */}
                                        <th>{t("order_date")}</th>
                                        <th>{t("customer_name")}</th>
                                        <th>{t("driver_name")}</th>
                                        <th>{t("status")}</th>
                                        <th>{t("order_amount")}</th>
                                        <th>{t("action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders && orders.map((data, i) => {
                                        let orderstatus = getStatus(data.status)
                                        let time = timeRemaining(data.orderDate,responseTime)
                                        return (<tr>
                                            <td>#{data.orderId}</td>

                                            <td>{moment(data.orderDate).format("DD MMMM, YYYY")}</td>
                                            <td>{data.customer.fullName}</td>
                                            <td>{data.driverName ? data.driverName : "N/A"}</td>

                                            <td className='status-txt-main'>
                                                {orderstatus == "Pending" ?
                                                    <>
                                                        <span className="delivered pending"></span>
                                                        {orderstatus ? t("pending") : ""}</> :

                                                    <>
                                                        {orderstatus == "Order Delivered" ? <>
                                                            <span className="delivered"></span>
                                                            {orderstatus ? t("delivered") : ""}</> :
                                                            <>

                                                        {orderstatus == "Order Completed" ? <>
                                                            <span className="delivered"></span>
                                                            {orderstatus ? t("completed") : ""}</> :
                                                            <>                                                                                                                 

                                                                {orderstatus == "Order Cancelled" ?
                                                                    <>
                                                                        <span className="delivered pending"></span>
                                                                        {orderstatus ? t("cancelled") : ""}
                                                                    </>

                                                                    :
                                                                    <>{orderstatus == "Driver Not Assigned" ?
                                                                      <><span className="delivered not-assigned"></span> {orderstatus ? t("driver_not_assigned") : ""}</>
                                                                            :<>
                                                                                {orderstatus == "Driver Assigned" ?
                                                                                    <> <span className="delivered driver-assigned"></span>
                                                                                        {orderstatus ? t("driver_assigned") : ""}</> :

                                                                                    <>
                                                                                        {orderstatus == "Rejected" ?
                                                                                            <>
                                                                                        <span className="delivered pending"></span>
                                                                                                {orderstatus ? t("rejected") : ""}
                                                                                            </> :
                                                                                            <>

                                                                                                <span className="delivered accepted"></span>
                                                                                                {orderstatus ? orderstatus : ""}</>}
</>

                                                                                }
                                                                            </>
                                                                        }
                                                                    </>

                                                                }
                                                            </>}
                                                        </>}

                                                    </>}

                                            </td>
                                            <td>${formatToColombianCurrency(data.totalAmount)}</td>
                                            <td> <TimerComponent type={"List"} data={data}   Getorders={Getorders} page={page}  limit={limit} status={status} search={search} responseTime={responseTime} time={time}/> </td>
                                        </tr>
                                        )
                                    }
                                    )}




                                </tbody>
                            </table>
                            {orders && orders.length == 0 &&
                                <div className="nodata centre">
                                    <img className="" src={require("../../../assets/images/no-data-found.png")} alt="img"
                                    />
                                    <span>{t("no_data_found")}</span>
                                </div>

                            }
                        </div>

                        {orders && orders.length > 0 && totalItems > 10 ? (
                            <div className="text-right">
                                <div className='pagination-tab'>
                                    <Pagination
                                        activePage={page + 1}
                                        itemsCountPerPage={10}
                                        totalItemsCount={totalItems}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => handlePageChange(e)}
                                    // prevPageText={"Prev"}
                                    // nextPageText={"Next"}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Container>
        </div>
    </>)
}

export default OrderManagementView;